import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Logo from "./logo"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          color
        }
      }
    }
  `)

  return (
    <header
      style={{
        background: data.site.siteMetadata.color,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: `250px`,
          }}
        >
          <Logo />
        </div>
      </div>
    </header>
  )
}
