import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-transparent.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.logo?.childImageSharp?.fluid) {
    return <div>Logo not found</div>
  }

  return (
    <Img
      style={{ pointerEvents: `none` }}
      fluid={data.logo.childImageSharp.fluid}
    />
  )
}
