import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBCardTitle,
  MDBIcon,
  MDBBtn,
  MDBCard,
  MDBCardBody,
} from "mdbreact"

import Particle from "./particle"

import "./cardParticles.css"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          secondaryColor
        }
      }
    }
  `)

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <MDBJumbotron
            className="m-0 p-0"
            fluid
            style={{
              position: `relative`,
            }}
          >
            <MDBCol
              className="py-5 text-center text-monospace"
              style={{
                zIndex: 10,
              }}
            >
              <MDBCardTitle className="text-white">
                Front Page Results
              </MDBCardTitle>
              <MDBRow>
                <MDBCol md="7" sm="12">
                  <p className="text-center text-white">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Repellat fugiat, laboriosam, voluptatem.
                  </p>
                </MDBCol>
                <MDBCol md="5" sm="12" className="text-left">
                  <MDBCard style={{ opacity: `0.9` }}>
                    <MDBCardBody>
                      <p>
                        <MDBIcon icon="paint-brush" /> Hand-crafted,
                        blazing-fast design powered by modern web frameworks.
                      </p>
                      <p>
                        <MDBIcon fab icon="google" /> Rank better in searches by
                        default, with built-in SEO!
                      </p>
                      <p>
                        <MDBIcon icon="user-shield" /> Secure by design!
                        Backups, DDoS Protection, SSL/TLS, Regular Updates and
                        more.
                      </p>
                      <p>
                        <MDBIcon icon="clock" /> Reliable uptime! Backed by
                        cloud services, we are able to achieve amazing
                        performance and deliver content 24/7.
                      </p>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              <hr
                className="my-3"
                style={{
                  backgroundColor: `white`,
                  border: 0,
                  height: `1px`,
                }}
              />
              <MDBBtn
                className="text-white"
                color="none"
                style={{
                  backgroundColor: data.site.siteMetadata.secondaryColor,
                }}
              >
                <MDBIcon icon="comment"></MDBIcon> Get in touch!
              </MDBBtn>
            </MDBCol>
            <Particle
              style={{
                height: `100%`,
                width: `100%`,
              }}
            />
          </MDBJumbotron>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
