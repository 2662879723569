import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBCardTitle,
  MDBIcon,
  MDBBtn,
} from "mdbreact"

import Form from "./form"

import city from "../images/city.gif"

export default () => {
  const MIN_WIDTH = "(min-width: 768px)"

  const [isDesktop, setIsDesktop] = React.useState(false)

  React.useEffect(() => {
    const handler = e => setIsDesktop(e.matches)

    setIsDesktop(window.matchMedia(MIN_WIDTH).matches)

    window.matchMedia(MIN_WIDTH).addEventListener("change", handler)
  }, [isDesktop])

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          secondaryColor
        }
      }
    }
  `)

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <MDBJumbotron fluid className="m-0 p-0" style={{ boxShadow: `none` }}>
            <MDBCol
              className="py-5 text-center text-monospace text-white"
              style={{
                backgroundImage: `url(${city})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `100% 100%`,
              }}
            >
              <MDBCardTitle>
                Web Design &amp; Hosting for Small Business
              </MDBCardTitle>
              <MDBRow className="mt-2">
                <MDBCol
                  md="6"
                  sm="12"
                  className={isDesktop ? "" : "order-last"}
                >
                  <Form />
                </MDBCol>
                <MDBCol md="6" sm="12" className="text-left">
                  <div style={{ display: `grid`, alignItems: `center` }}>
                    <p>
                      <MDBIcon icon="rocket" /> Lightning-fast load times!
                    </p>
                    <p className="mt-2">
                      <MDBIcon icon="cloud" /> Modern hosting in the Cloud!
                    </p>
                    <p className="mt-2">
                      <MDBIcon icon="lock" /> Secure by design! And always
                      available!
                    </p>
                  </div>
                </MDBCol>
              </MDBRow>
              <hr
                className="my-3"
                style={{
                  backgroundColor: `white`,
                  border: 0,
                  height: `1px`,
                }}
              />
              <MDBBtn
                className="text-white"
                color="none"
                style={{
                  backgroundColor: data.site.siteMetadata.secondaryColor,
                }}
              >
                <MDBIcon icon="comment" /> Get in touch!
              </MDBBtn>
            </MDBCol>
          </MDBJumbotron>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
