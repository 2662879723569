import React from "react"

import { MDBContainer, MDBRow, MDBCol, MDBInput } from "mdbreact"

export default () => (
  <MDBContainer>
    <MDBRow>
      <MDBCol>
        <form className="form-group">
          <MDBInput group label="Name" className="text-white" />
          <MDBInput group label="E-mail" className="text-white" />
          <MDBInput group label="Phone #" className="text-white" />
        </form>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
)
