import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Gif from "../components/cardGif"
import Particles from "../components/cardParticles"
import Stepper from "../components/stepper"

const Index = () => (
  <Layout>
    <SEO title="Home" />
    <Gif />
    <Stepper />
    <Particles />
  </Layout>
)

export default Index
