import React from "react"

import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact"

export default () => (
  <MDBContainer>
    <MDBRow className="m-5 text-monospace">
      <MDBCol md="3" sm="12" className="text-center">
        <p>Get in touch!</p>
        <p>
          <MDBIcon icon="comments" className="fa-3x"></MDBIcon>
        </p>
        <p>We will discuss your needs and goals in a quick conversation.</p>
      </MDBCol>
      <MDBCol md="2" sm="12">
        <hr
          className="my-3"
          style={{
            backgroundColor: `black`,
            border: 0,
            height: `1px`,
          }}
        ></hr>
      </MDBCol>
      <MDBCol md="2" sm="12" className="text-center">
        <p>Get online!</p>
        <p>
          <MDBIcon icon="cloud-upload-alt" className="fa-3x"></MDBIcon>
        </p>
        <p>Our quick, agile design process gets you up and running, fast!</p>
      </MDBCol>
      <MDBCol md="2" sm="12">
        <hr
          className="align-middle my-3"
          style={{
            backgroundColor: `black`,
            border: 0,
            height: `1px`,
          }}
        ></hr>
      </MDBCol>
      <MDBCol md="3" sm="12" className="text-center">
        <p>Get noticed!</p>
        <p>
          <MDBIcon icon="newspaper" className="fa-3x"></MDBIcon>
        </p>
        <p>
          Go far, and rank highly! Continue to stand-out from the competition.
        </p>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
)
