import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          color
          title
        }
      }
    }
  `)

  return (
    <footer
      className="text-center text-monospace text-white py-3"
      style={{
        background: data.site.siteMetadata.color,
      }}
    >
      © {new Date().getFullYear()}
      {` `}
      {data.site.siteMetadata?.title || `Title`}
    </footer>
  )
}
